const WIB_PREFIX = 'wib_'

const SHOPS = {
  DE: 'weinfuerst.de',
  AT: 'weinfuerst.at',
  NL: 'wijnjuweel.nl',
  FR: 'vinroyal.fr',
  CH: 'weinfuerst.ch',
  IT: 'vinoreale.it',
  SK: 'vinokral.sk',
  CZ: 'vinokral.cz',
  BG: 'vinokral.bg',
  HU: 'borbaro.hu',
  RO: 'vinregal.ro',
  WIB_DE: 'wine-in-black.de',
  WIB_AT: 'wine-in-black.at',
  WIB_NL: 'wineinblack.nl',
  WIB_FR: 'wine-in-black.fr',
  WIB_CH: 'wine-in-black.ch'
}
export const CONFIG_MAP = {
  [SHOPS.DE]: { siteId: 11000, locale: 'de-DE', page: 'Weinfürst', logo: 'logo_weinfuerst.svg', translationPrefix: '', theme: 'weinfuerst', goldClub: true },
  [SHOPS.AT]: { siteId: 11001, locale: 'de-AT', page: 'Weinfürst', logo: 'logo_weinfuerst.svg', translationPrefix: '', theme: 'weinfuerst', goldClub: true },
  [SHOPS.NL]: { siteId: 11002, locale: 'nl-NL', page: 'Wijnjuweel', logo: 'logo_wijnjuweel.svg', translationPrefix: '', theme: 'weinfuerst', goldClub: true },
  [SHOPS.FR]: { siteId: 11003, locale: 'fr-FR', page: 'Vinroyal', logo: 'logo_vinroyal.svg', translationPrefix: '', theme: 'weinfuerst', goldClub: true },
  [SHOPS.CH]: { siteId: 11005, locale: 'de-CH', page: 'Weinfürst', logo: 'logo_weinfuerst.svg', translationPrefix: '', theme: 'weinfuerst', goldClub: false },
  [SHOPS.IT]: { siteId: 11008, locale: 'it-IT', page: 'Vinoreale', logo: 'logo_vinoreale.svg', translationPrefix: '', theme: 'weinfuerst', goldClub: true },
  [SHOPS.SK]: { siteId: 11010, locale: 'sk-SK', page: 'Vínokráľ', logo: 'logo_vinokral_sk.svg', translationPrefix: '', theme: 'weinfuerst', goldClub: true },
  [SHOPS.CZ]: { siteId: 11011, locale: 'cz-CZ', page: 'Vínokrál', logo: 'logo_vinokral_cz.svg', translationPrefix: '', theme: 'weinfuerst', goldClub: true },
  [SHOPS.HU]: { siteId: 11012, locale: 'hu-HU', page: 'Borbáró', logo: 'logo_borbaro.svg', translationPrefix: '', theme: 'weinfuerst', goldClub: true },
  [SHOPS.RO]: { siteId: 11013, locale: 'ro-RO', page: 'Vinregal', logo: 'logo_vinregal.svg', translationPrefix: '', theme: 'weinfuerst', goldClub: true },
  [SHOPS.BG]: { siteId: 11014, locale: 'bg-BG', page: 'Vínokral', logo: 'logo_vinokral_bg.svg', translationPrefix: '', theme: 'weinfuerst', goldClub: true },
  [SHOPS.WIB_DE]: { siteId: 12000, locale: 'de-DE', page: 'Wine in Black', logo: 'logo_wib_de.svg', translationPrefix: WIB_PREFIX, theme: 'wib', isWiB: true, goldClub: false },
  [SHOPS.WIB_AT]: { siteId: 12001, locale: 'de-AT', page: 'Wine in Black', logo: 'logo_wib_de.svg', translationPrefix: WIB_PREFIX, theme: 'wib', isWiB: true, goldClub: false },
  [SHOPS.WIB_NL]: { siteId: 12002, locale: 'nl-NL', page: 'Wine in Black', logo: 'logo_wib_de.svg', translationPrefix: WIB_PREFIX, theme: 'wib', isWiB: true, goldClub: false },
  [SHOPS.WIB_FR]: { siteId: 12003, locale: 'fr-FR', page: 'Wine in Black', logo: 'logo_wib_de.svg', translationPrefix: WIB_PREFIX, theme: 'wib', isWiB: true, goldClub: false },
  [SHOPS.WIB_CH]: { siteId: 12005, locale: 'de-CH', page: 'Wine in Black', logo: 'logo_wib_de.svg', translationPrefix: WIB_PREFIX, theme: 'wib', isWiB: true, goldClub: false }
}

const CONTACT_MAP = {
  [SHOPS.DE]: { phone: '06131 / 95 04 990', email: 'info@weinfuerst.de' },
  [SHOPS.AT]: { phone: '+43 720 883977', email: 'info@weinfuerst.at' },
  [SHOPS.NL]: { phone: '+31 85 888 9268', email: 'info@wijnjuweel.nl' },
  [SHOPS.FR]: { phone: '01 80 144 559', email: 'info@vinroyal.fr' },
  [SHOPS.CH]: { phone: '+41 2255 10 130', email: 'info@weinfuerst.ch' },
  [SHOPS.IT]: { phone: '800 725 902', email: 'info@vinoreale.it' },
  [SHOPS.SK]: { phone: '+421 2/334 183 59', email: 'info@vinokral.sk' },
  [SHOPS.CZ]: { phone: '+420 228 885 469', email: 'info@vinokral.cz' },
  [SHOPS.HU]: { phone: '+36 800 80663', email: 'info@borbaro.hu' },
  [SHOPS.RO]: { phone: '+40 376 300 667', email: 'info@vinregal.ro' },
  [SHOPS.BG]: { phone: '', email: 'info@vinokral.bg' },
  [SHOPS.WIB_DE]: { phone: '06131 / 95 04 996', email: 'info@wine-in-black.de' },
  [SHOPS.WIB_AT]: { phone: '+43 720 881701', email: 'info@wine-in-black.at' },
  [SHOPS.WIB_NL]: { phone: '+31 85 888 9749', email: 'info@wineinblack.nl' },
  [SHOPS.WIB_FR]: { phone: '01 84 88 46 53', email: 'info@wine-in-black.fr' },
  [SHOPS.WIB_CH]: { phone: '071 58 80 493', email: 'info@wine-in-black.ch' }
}

const LEGAL_CONFIG = {
  [SHOPS.DE]: {
    legalNotice: 'https://www.weinfuerst.de/pages/impressum',
    buyingConditions: 'https://www.weinfuerst.de/pages/agb',
    dataProtection: 'https://www.weinfuerst.de/pages/datenschutzerklarung'
  },
  [SHOPS.AT]: {
    legalNotice: 'https://www.weinfuerst.at/pages/impressum',
    buyingConditions: 'https://www.weinfuerst.at/pages/agb',
    dataProtection: 'https://www.weinfuerst.at/pages/datenschutzerklarung'
  },
  [SHOPS.NL]: {
    legalNotice: 'https://wijnjuweel.nl/pages/colofon',
    buyingConditions: 'https://wijnjuweel.nl/pages/algemene-voorwaarden',
    dataProtection: 'https://wijnjuweel.nl/pages/gegevensbeschermingsverklaring'
  },
  [SHOPS.FR]: {
    legalNotice: 'https://vinroyal.fr/pages/mentions-legales',
    buyingConditions: 'https://vinroyal.fr/pages/cgv',
    dataProtection: 'https://vinroyal.fr/pages/privacy'
  },
  [SHOPS.CH]: {
    legalNotice: 'https://weinfuerst.ch/pages/impressum',
    buyingConditions: 'https://weinfuerst.ch/pages/agb',
    dataProtection: 'https://weinfuerst.ch/pages/datenschutzerklarung'
  },
  [SHOPS.IT]: {
    legalNotice: 'https://vinoreale.it/pages/impronta',
    buyingConditions: 'https://vinoreale.it/pages/condizioni-generali-di-vendita',
    dataProtection: 'https://vinoreale.it/pages/informativa-sulla-privacy'
  },
  [SHOPS.SK]: {
    legalNotice: 'https://vinokral.sk/pages/ochrana-osobnych-udajov',
    buyingConditions: 'https://vinokral.sk/pages/zasady-vratenia-penazi',
    dataProtection: 'https://vinokral.sk/pages/obchodne-podmienky'
  },
  [SHOPS.CZ]: {
    legalNotice: 'https://vinokral.cz/pages/kontakty',
    buyingConditions: 'https://vinokral.cz/pages/obchodni-podminky',
    dataProtection: 'https://vinokral.cz/pages/ochrana-osobnich-udaju'
  },
  [SHOPS.HU]: {
    legalNotice: 'https://borbaro.hu/pages/impresszum',
    buyingConditions: 'https://borbaro.hu/pages/pages/aszf',
    dataProtection: 'https://borbaro.hu/pages/adatvedelem'
  },
  [SHOPS.RO]: {
    legalNotice: 'https://vinregal.ro/pages/informatii-juridice',
    buyingConditions: 'https://vinregal.ro/pages/termeni-si-conditii',
    dataProtection: 'https://vinregal.ro/pages/protejarea-datelor'
  },
  [SHOPS.BG]: {
    legalNotice: 'https://vinokral.bg/pages/kontakti',
    buyingConditions: 'https://vinokral.bg/pages/obshti-usloviya',
    dataProtection: 'https://vinokral.bg/pages/politika-za-poveritelnost'
  },
  [SHOPS.WIB_DE]: {
    legalNotice: 'https://www.wine-in-black.de/impressum',
    buyingConditions: 'https://www.wine-in-black.de/agb',
    dataProtection: 'https://www.wine-in-black.de/datenschutz'
  },
  [SHOPS.WIB_AT]: {
    legalNotice: 'https://www.wine-in-black.at/impressum',
    buyingConditions: 'https://www.wine-in-black.at/agb',
    dataProtection: 'https://www.wine-in-black.at/datenschutz'
  },
  [SHOPS.WIB_NL]: {
    legalNotice: 'https://www.wineinblack.nl/colofon',
    buyingConditions: 'https://www.wineinblack.nl/algemene-voorwaarden',
    dataProtection: 'https://www.wineinblack.nl/privacyverklaring'
  },
  [SHOPS.WIB_FR]: {
    legalNotice: 'https://www.wine-in-black.fr/mentionslegales',
    buyingConditions: 'https://www.wine-in-black.fr/cgv',
    dataProtection: 'https://www.wine-in-black.fr/confidentialite'
  },
  [SHOPS.WIB_CH]: {
    legalNotice: 'https://www.wine-in-black.ch/impressum',
    buyingConditions: 'https://www.wine-in-black.ch/agb',
    dataProtection: 'https://www.wine-in-black.ch/datenschutz'
  }
}

export {
    SHOPS,
    WIB_PREFIX,
    CONTACT_MAP,
    LEGAL_CONFIG
}
